


























import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import Player from '@/components/common/Player.vue'
import Modal from '@/components/common/Modal.vue'
import isOS from '@/helper/detectOs'
@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title,
    ProgressLine,
    Player,
    Modal
  }
})
export default class WelcomeDemography extends Vue {
  private mounted (): void {
    this.$store.dispatch('walkthrough/resetResultSubmit')
    if (window.innerWidth < 767 && isOS()) window.scrollTo(0, 1)

    // if (this.isDemographyFilled) {
    //   this.$router.push({ name: 'pre test video' })
    // }
  }

  async created (): Promise<void> {
    // localStorage.setItem('testResult', btoa('test_result=3'))
    const emptyDemography = {
      fullName: '',
      passportNumber: '',
      flyingDate: ''
    }
    this.$store.dispatch('walkthrough/setUserInfo', emptyDemography)
    await this.$store.dispatch('auth/deleteResultData')
    await this.$store.dispatch('auth/setResultData')
  }

  get isDemographyFilled ():boolean {
    const data = this.$store.getters['auth/getUserSessionData']
    if (Object.prototype.hasOwnProperty.call(data, 'is_demographic_filled')) {
      if (data.is_demographic_filled) {
        return true
      }
    }
    return false
  }

  get videoUrls (): Array<string> {
    return this.$store.state.video.videoUrls[0]
  }
}
